import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Image from "@components/common/image"

const LookInEntrepreneurs = () => {
  return (
    <div className="look-in">
      <Container>
        <Row>
          <Col xs={12} lg={6} className="px-0">
            <Image
              data-sal="slide-right"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
              fluid
              src="investment-philosophy-2.png"
              alt="What we look for in Entrepreneurs"
              title="What we look for in Entrepreneurs"
            />
          </Col>

          <Col xs={12} lg={6} className="section-content p-x-30">
            <div>
              <h4
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="section-title"
              >
                What we look for in{" "}
                <span className="highlight">Entrepreneurs</span>
              </h4>
              <p
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                We look for individuals who reject consensus to create new
                opportunities, and those who question fundamental assumptions.
              </p>

              <ul className="look-in-list list-unstyled">
                <li
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <Image
                    className="list-icon"
                    fluid
                    src="chevron-arrow-icon.png"
                    alt=">"
                    title=""
                  />
                  <p className="list-content">
                    Smart minds that think and act fast
                  </p>
                </li>
                <li
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <Image
                    className="list-icon"
                    fluid
                    src="chevron-arrow-icon.png"
                    alt=">"
                    title=""
                  />
                  <p className="list-content">
                    Mission-driven men and women with exceptional
                    problem-solving skills, systematic thinking, and technical
                    know-how
                  </p>
                </li>
                <li
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <Image
                    className="list-icon"
                    fluid
                    src="chevron-arrow-icon.png"
                    alt=">"
                    title=""
                  />
                  <p className="list-content">
                    Forward-looking thinkers with their own vision for the
                    future
                  </p>
                </li>
                <li
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <Image
                    className="list-icon"
                    fluid
                    src="chevron-arrow-icon.png"
                    alt=">"
                    title=""
                  />
                  <p className="list-content">
                    A robust business offering, strong value proposition and
                    clear target audiences
                  </p>
                </li>
                <li
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <Image
                    className="list-icon"
                    fluid
                    src="chevron-arrow-icon.png"
                    alt=">"
                    title=""
                  />
                  <p className="list-content">
                    Structured and well-connected teams of 2-3 founders, with
                    complementary skills and diverse capabilities
                  </p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LookInEntrepreneurs
