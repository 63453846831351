import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Image from "@components/common/image"

const InvestmentPhilosophyHero = () => {
  return (
    <Container className="investment-philosophy-hero">
      <Row className="align-items-center">
        <Col xs={12} md={6} lg={5} className="order-0 order-md-1">
          <div
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="1000"
            className="section-content mb-0 text-center text-md-left"
          >
            <h4 className="section-title">
              We guide business pilots to{" "}
              <span className="highlight">create value</span> and make an impact
            </h4>

            <p>
              Our philosophy is to partner with entrepreneurs who we consider to
              be at the forefront of business innovation.
            </p>
          </div>
        </Col>
        <Col xs={12} md={5} className="order-1 order-md-0">
          <Image
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease"
            fluid
            src="investment-philosophy-1.png"
            alt="Investment Philosophy"
            title="Investment Philosophy"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default InvestmentPhilosophyHero
