function count(el) {
  const from = parseInt(el.dataset.from)
  const to = parseInt(el.dataset.to)
  const time = el.dataset.time ? parseInt(el.dataset.time) : 1000
  const delta = to - from
  const deltaPositive = delta > 0 ? 1 : 0
  const changeTime = 10

  let currentNumber = from
  let value = (delta * changeTime) / time
  let interval1
  const changeNumber = () => {
    currentNumber += value
    //   check if current number reached to number to

    currentNumber =
      (deltaPositive && currentNumber >= to) ||
      (!deltaPositive && currentNumber <= to)
        ? to
        : currentNumber

    el.innerHTML = `${parseInt(currentNumber)}`;
    
    if (currentNumber === to) {
        clearInterval(interval1)
    }
    
  }
  interval1 = setInterval(changeNumber, changeTime)
}

export default count
