import React from "react";

import Layout from "@components/layout"
import SEO from "@components/common/seo"
import ApplyForFunding from "@components/views/home/apply-for-funding";
import InvestmentPhilosophyHero from "../components/views/investment-philosophy/investment-philosophy-hero";
import UniqueOffering from "../components/views/investment-philosophy/unique-offering";
import OurInvestmentProcess from "../components/views/investment-philosophy/our-investment-process";
import Stats from "../components/views/investment-philosophy/stats.js";
import LookInEntrepreneurs from "../components/views/investment-philosophy/look-in-entrepreneurs";

const InvestmentPhilosophyPage = () => {
    return (
        <Layout className="investment-philosophy">
            <SEO title="Investment Philosophy" />

            <InvestmentPhilosophyHero />
            <UniqueOffering />

            <OurInvestmentProcess />

            <Stats />
            <LookInEntrepreneurs />

            <ApplyForFunding />
        </Layout>
    )
}

export default InvestmentPhilosophyPage;