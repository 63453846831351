import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const OurInvestmentProcess = () => {
  const totalProcess = 7
  const [activeProcess, setActiveProcess] = React.useState(null)

  React.useEffect(() => {
    let timeout
    if (activeProcess !== null) {
      timeout = setTimeout(() => {
        setActiveProcess(activeProcess === totalProcess ? 1 : activeProcess + 1)
      }, 2000)
    } else {
      setActiveProcess(1)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [activeProcess])

  return (
    <div className="our-investment-process">
      <Container fluid="xl">
        <Row>
          <Col
            xs={12}
            lg={3}
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000"
            className="section-title-wrapper p-x-30"
          >
            <div className="section-content mb-0">
              <h4 className="section-title">
                <span>Our </span>
                <span className="highlight">Investment </span>
                <span>Process</span>
              </h4>

              <Link
                to="/apply-for-funding/"
                className="btn btn-primary text-white d-none d-lg-inline-flex"
              >
                Apply for Funding
              </Link>

              <Link
                to="/apply-for-funding/"
                className="btn btn-secondary text-white d-lg-none"
              >
                Apply for Funding
              </Link>
            </div>
          </Col>
          <Col
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000"
            xs={12}
            lg={9}
            className="process-wrapper"
          >
            <Row className="process-row">
              <Col
                className={`process-col ${activeProcess === 1 ? "active" : ""}`}
                data-process="01"
              >
                <h2 className="process-title">Application</h2>
                <p className="process-description">
                  Completing the funding application form on our webpage.
                </p>
                <a href="/apply-for-funding" className="process-status">
                  Apply Now
                </a>
              </Col>

              <Col
                className={`process-col ${activeProcess === 2 ? "active" : ""}`}
                data-process="02"
              >
                <h2 className="process-title">Opportunity screening</h2>
                <p className="process-description">
                  Reviewing the opportunity's quality, geography, sector, and
                  stage.
                </p>
                <p className="process-status">1 Week</p>
              </Col>
            </Row>

            <Row className="process-row-extra d-none d-lg-flex">
              <Col className="process-col process-col-extra"></Col>
              <Col className="process-col process-col-extra"></Col>
            </Row>

            <Row className="process-row">
              <Col
                className={`process-col ${activeProcess === 3 ? "active" : ""}`}
                data-process="03"
              >
                <h2 className="process-title">Opportunity assesment</h2>
                <p className="process-description">
                  Analyzing the target market, product, team, competition, and
                  traction.
                </p>
                <p className="process-status">1 Week</p>
              </Col>

              <Col
                className={`process-col ${activeProcess === 4 ? "active" : ""}`}
                data-process="04"
              >
                <h2 className="process-title">
                  Short interview with the <span>founder (s)</span>
                </h2>
                <p className="process-description">
                  Introductory meeting to assess and determine synergies between
                  Al Falaj and the applicant.
                </p>
                <p className="process-status">30 Mins</p>
              </Col>
            </Row>

            <Row className="process-row-extra d-none d-lg-flex">
              <Col className="process-col process-col-extra"></Col>
              <Col className="process-col process-col-extra"></Col>
            </Row>

            <Row className="process-row">
              <Col
                className={`process-col ${activeProcess === 5 ? "active" : ""}`}
                data-process="05"
              >
                <h2 className="process-title">Pitch to investment committee</h2>
                <p className="process-description">
                  Detailed presentation of the business concept and model to our
                  investment committee members.
                </p>
                <p className="process-status">1-2 HRS</p>
              </Col>

              <Col
                className={`process-col ${activeProcess === 6 ? "active" : ""}`}
                data-process="06"
              >
                <h2 className="process-title">Due Diligence</h2>
                <p className="process-description">
                  Pragmatic commercial and legal due diligence exercises to
                  determine the viability of the deal.
                </p>
                <p className="process-status">3-4 Weeks</p>
              </Col>
            </Row>

            <Row className="process-row-extra d-none d-lg-flex">
              <Col className="process-col process-col-extra"></Col>
              <Col className="process-col process-col-extra"></Col>
            </Row>

            <Row className="process-row">
              <Col
                className={`process-col ${activeProcess === 7 ? "active" : ""}`}
                data-process="07"
              >
                <h2 className="process-title">Closing</h2>
                <p className="process-description">
                  Drafting and negotiating investment agreement documents.
                </p>
                <p className="process-status">2 Weeks</p>
              </Col>

              <Col className="process-col process-col-extra"></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurInvestmentProcess
