import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Image from "@components/common/image"

const UniqueOffering = () => {
  return (
    <Container className="unique-offering">
      <Row className="justify-content-center text-center">
        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          xs={12}
          md={8}
        >
          <h2 className="section-title text-uppercase">Our Unique Offering</h2>
        </Col>
        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          xs={12}
          md={8}
        >
          <h3>
            We are committed to accelerating the development of our portfolio
            companies
          </h3>
        </Col>
      </Row>

      <Row className="offerings-row">
        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="customer-support-icon.png"
              alt="Unparalleled support"
              title="Unparalleled support"
            />
          </div>
          <h2 className="section-subtitle">Unparalleled support</h2>
          <p>
            Unparalleled support to help entrepreneurs build agile, resilient,
            and sustainable ventures.
          </p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="access-icon.png"
              alt="Direct access"
              title="Direct access"
            />
          </div>
          <h2 className="section-subtitle">Direct access</h2>
          <p>
            Direct access to smart capital and holistic suite of funding tools.
          </p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="process-icon.png"
              alt="Efficient processes"
              title="Efficient processes"
            />
          </div>
          <h2 className="section-subtitle">Efficient processes</h2>
          <p>Pragmatic and efficient due diligence processes.</p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="deployment-icon.png"
              alt="Capital deployment"
              title="Capital deployment"
            />
          </div>
          <h2 className="section-subtitle">Capital deployment</h2>
          <p>
            Capital deployment in follow-on rounds for companies that
            demonstrate strong traction.
          </p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="programmes-icon.png"
              alt="Tailored programmes"
              title="Tailored programmes"
            />
          </div>
          <h2 className="section-subtitle">Tailored programmes</h2>
          <p>Tailored professional development programmes.</p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="partnership-icon.png"
              alt="Strong partnerships"
              title="Strong partnerships"
            />
          </div>
          <h2 className="section-subtitle">Strong partnerships</h2>
          <p>
            Long-standing partnerships with global organisations and government
            hubs.
          </p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="expertise-icon.png"
              alt="Diverse expertise"
              title="Diverse expertise"
            />
          </div>
          <h2 className="section-subtitle">Diverse expertise</h2>
          <p>
            Diverse expertise and networks which can add substantial value to
            entrepreneurs and their ventures.
          </p>
        </Col>

        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        >
          <div className="section-icon mx-auto mx-md-0">
            <Image
              fluid
              src="csr-icon.png"
              alt="CSR initiatives"
              title="CSR initiatives"
            />
          </div>
          <h2 className="section-subtitle">CSR initiatives</h2>
          <p>
            Impactful annual CSR initiatives in alliance with exceptional
            start-ups.
          </p>
        </Col>
        <Col
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section-content text-center text-md-left"
        ></Col>
      </Row>
    </Container>
  )
}

export default UniqueOffering
