import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import count from "../../../utils/count"

const Stats = () => {
  const countRefs = React.useRef(
    [1, 2, 3, 4, 5, 6].map(() => React.createRef())
  )
  const statsContainerRef = React.useRef()

  React.useEffect(() => {
    if (statsContainerRef && statsContainerRef.current) {
      statsContainerRef.current.addEventListener("sal:in", ({ detail }) => {
        countRefs.current.forEach(ref => {
          count(ref.current)
        })
      })
    }
  }, [])

  return (
    <Container
      ref={statsContainerRef}
      data-sal="fade"
      data-sal-delay="0"
      data-sal-easing="ease"
      className="stats-wrapper"
    >
      <Row>
        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            $
            <span
              ref={countRefs.current[0]}
              data-from="1"
              data-to="3"
              data-time="1000"
            >
              3
            </span>{" "}
            million
          </h4>
          <h2 className="stats-title">in funding rounds led</h2>
        </Col>

        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            $
            <span ref={countRefs.current[1]} data-from="1" data-to="20" data-time="2000">
              20
            </span>
            + million
          </h4>
          <h2 className="stats-title">total capital allocated</h2>
        </Col>

        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            <span ref={countRefs.current[2]} data-from="1" data-to="20" data-time="2000">
              20
            </span>
            +
          </h4>
          <h2 className="stats-title">years of experience</h2>
        </Col>
      </Row>
      
      <Row>
        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            <span ref={countRefs.current[3]} data-from="1" data-to="3" data-time="1000">
              3
            </span>
          </h4>
          <h2 className="stats-title">successful exits</h2>
        </Col>

        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            <span ref={countRefs.current[4]} data-from="1" data-to="7" data-time="1000">
              7
            </span>
          </h4>
          <h2 className="stats-title">Different markets</h2>
        </Col>

        <Col className="stats-col text-center text-md-left">
          <h4 className="stats-number">
            <span ref={countRefs.current[5]} data-from="1" data-to="10" data-time="2000">
              10
            </span>
            +
          </h4>
          <h2 className="stats-title">investments</h2>
        </Col>
      </Row>
    </Container>
  )
}

export default Stats
